import { Action, Reducer } from 'redux';
import { IResult } from '../model/Result';
import { Producto } from '../model/producto';
import { AppThunkAction } from './';
import { ListaFormula } from '../model/lista_formula';
import {
  RequestProductCost,
  ReceiveProductCost,
  FailOnRequestProductCost,
  RequestReplacementCost,
  ReceiveReplacementCost,
  RequestAverageCost,
  ReceiveAverageCost,
  FailOnRequestReplacementCost,
  FailOnRequestAverageCost,
  RequestProductDelete,
  ReceiveProductDelete,
  FailOnRequestProductDelete
} from '../redux/Actions/ProductActions';
import { ProductCost } from '../model/productCost';
import { ErrorType } from '../model/FetchError';

// functions
import * as Functions from '../redux/Functions/Commons';

// services
import * as ProductServices from '../services/ProductService';
import { IReplacementCost } from '../model/replacementCost';
import { IAverageCost } from '../model/averageCost';

interface RequestAllProducts {
  type: 'REQUEST_ALL_PRODUCTS';
  isFiltered: boolean;
  filterCriteria: string | undefined;
}

interface ReceiveAllProducts {
  type: 'RECEIVE_ALL_PRODUCTS';
  isFiltered: boolean;
  filterCriteria: string | undefined;
  products: Producto[];
}

interface FailOnRequestAllProducts {
  type: 'GET_ALL_PRODUCTS_FAIL';
  error: ErrorType;
}
interface setProductFormula {
  type: 'SET_PRODUCT_FORMULA';
  formula: ListaFormula;
}

interface getProduct {
  type: 'GET_PRODUCTO';
  producto: Producto;
}
interface setProduct {
  type: 'SET_PRODUCT';
  product?: Producto;
}
interface RequestSaveProduct {
  type: 'REQUEST_SAVE_PRODUCT';
}

interface ReceiveSavedProduct {
  type: 'RECEIVE_SAVED_PRODUCT';
  producto: Producto;
}
interface updateLoading {
  type: 'UPDATE_LOADING';
  isLoading: boolean;
}

interface RequestLastDensity {
  type: 'REQUEST_LAST_DENSITY';
  productId: number;
}

interface GetLastDensity {
  type: 'GET_LAST_DENSITY';
  density: number;
}

interface fetchError {
  type: 'FETCH_ERROR';
  StatusOK: boolean;
  Errors: any;
}

type KnownAction =
  | RequestAllProducts
  | ReceiveAllProducts
  | FailOnRequestAllProducts
  | setProduct
  | RequestSaveProduct
  | ReceiveSavedProduct
  | getProduct
  | fetchError
  | setProductFormula
  | updateLoading
  | RequestProductCost
  | ReceiveProductCost
  | FailOnRequestProductCost
  | RequestReplacementCost
  | ReceiveReplacementCost
  | RequestAverageCost
  | ReceiveAverageCost
  | FailOnRequestReplacementCost
  | FailOnRequestAverageCost
  | RequestProductDelete
  | ReceiveProductDelete
  | FailOnRequestProductDelete
  | RequestLastDensity
  | GetLastDensity;

export const actionCreators = {
  setLoading:
    (flag: boolean): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: 'UPDATE_LOADING', isLoading: flag });
    },
  getAllProductos:
    (
      pageNumber?: string,
      pageSize?: string,
      text?: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      let params = null;
      if (pageNumber && pageSize && text) {
        params = new URLSearchParams({
          PageNumber: pageNumber,
          PageSize: pageSize,
          SearchText: text
        });
      }
      if (pageNumber && pageSize) {
        params = new URLSearchParams({
          PageNumber: pageNumber,
          PageSize: pageSize
        });
      }
      if (pageNumber && text) {
        params = new URLSearchParams({
          PageNumber: pageNumber,
          SearchText: text
        });
      }
      if (pageSize && text) {
        params = new URLSearchParams({
          PageSize: pageSize,
          SearchText: text
        });
      }
      if (pageNumber) {
        params = new URLSearchParams({
          PageNumber: pageNumber
        });
      }
      if (pageSize) {
        params = new URLSearchParams({
          PageSize: pageSize
        });
      }
      if (text) {
        params = new URLSearchParams({
          SearchText: text
        });
      }
      dispatch({
        type: 'REQUEST_ALL_PRODUCTS',
        filterCriteria: text,
        isFiltered: text ? true : false
      });
      if (params) {
        await fetch(
          process.env.REACT_APP_API_ENDPOINT +
            'v1/productos?' +
            params.toString()
        )
          .then((response) => response.json() as Promise<any>)
          .then((data) => {
            dispatch({
              type: 'RECEIVE_ALL_PRODUCTS',
              isFiltered: text ? true : false,
              filterCriteria: text,
              products: data.data.sort(Functions.DynamicSort('codigo'))
            });
          });
      } else {
        await fetch(process.env.REACT_APP_API_ENDPOINT + 'v1/productos')
          .then((response) => response.json() as Promise<any>)
          .then((data) => {
            dispatch({
              type: 'RECEIVE_ALL_PRODUCTS',
              isFiltered: false,
              filterCriteria: undefined,
              products: data.data.sort(Functions.DynamicSort('codigo'))
            });
          });
      }
    },
  setProducto:
    (product?: Producto): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: 'SET_PRODUCT', product: product });
    },
  getFormulaKilo:
    (id: Number, cantidad: Number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          'v1/formulas/productos/' +
          id +
          '/' +
          cantidad
      )
        .then((response) => response.json() as Promise<any>)
        .then((data) => {
          // console.log(data.data);
          const product: Producto = data.data.producto;
          const formula: any = {
            id: data.data.producto.id,
            productoId: data.data.producto.id,
            lista_elementos: data.data.lista_elementos
          };
          product.formula = formula;
          // console.log(producto)
          dispatch({ type: 'SET_PRODUCT', product: product });
        });
    },
  getProducto:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await fetch(process.env.REACT_APP_API_ENDPOINT + 'v1/productos/' + id)
        .then((response) => response.json() as Promise<IResult<Producto>>)
        .then((data) => {
          dispatch({ type: 'SET_PRODUCT', product: data.data });
        })
        .catch((err: any) => {
          console.log('Error', err);
        });
    },

  saveProducto:
    (producto: Producto): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      console.log(producto);
      dispatch({ type: 'REQUEST_SAVE_PRODUCT' });
      const request_url =
        process.env.REACT_APP_API_ENDPOINT +
        'v1/productos/' +
        (producto.id === undefined || producto.id === -1 ? '' : producto.id);
      await fetch(request_url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method:
          producto.id === undefined || producto.id === -1 ? 'POST' : 'PUT',
        body: JSON.stringify(producto)
      })
        .then((response) => response.json() as Promise<IResult<Producto>>)
        .then((data: IResult<Producto>) => {
          if (data.data) {
            const savedProducto = data.data;
            if (savedProducto.id !== producto.id) savedProducto.isNew = true;
            dispatch({
              type: 'RECEIVE_SAVED_PRODUCT',
              producto: savedProducto
            });
          } else {
            dispatch({
              type: 'FETCH_ERROR',
              StatusOK: false,
              Errors: data.errors
            });
          }
        })
        .catch((err: any) => {
          dispatch({ type: 'FETCH_ERROR', StatusOK: false, Errors: err });
        });
    },
  SetFormulaToSelectedProduct:
    (formula: ListaFormula): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: 'SET_PRODUCT_FORMULA', formula: formula });
    },
  GetProductCost:
    (productId: number): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: 'REQUEST_PRODUCT_COST', productId: productId });
      ProductServices.GetProductCost(productId)
        .then((Cost: ProductCost) =>
          dispatch({ type: 'RECEIVE_PRODUCT_COST', productCost: Cost })
        )
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRODUCT_COST_FAIL',
            error: error
          })
        );
    },

    GetReplacementCost:
    (productId: number): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: 'REQUEST_REPLACEMENT_COST', productId: productId });
      ProductServices.GetReplacementCost(productId)
        .then((Cost: ProductCost) =>
          dispatch({ type: 'RECEIVE_REPLACEMENT_COST', productCost: Cost })
        )
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRODUCT_REPLACEMENT_COST_FAIL',
            error: error
          })
        );
    },

    GetLastDensity:
    (productId: number): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: 'REQUEST_LAST_DENSITY', productId: productId });
      ProductServices.GetLastDensity(productId)
        .then((density: number) =>
          dispatch({ type: 'GET_LAST_DENSITY', density: density })
        )
    },

    GetAverageCost:
    (productId: number): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: 'REQUEST_AVERAGE_COST', productId: productId });
      ProductServices.GetAverageCost(productId)
        .then((Cost: ProductCost) =>
          dispatch({ type: 'RECEIVE_AVERAGE_COST', productCost: Cost })
        )
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRODUCT_AVERAGE_COST_FAIL',
            error: error
          })
        );
    },

  DeleteProduct:
    (productId: number, token: string): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: 'REQUEST_PRODUCT_DELETE', productId: productId });
      ProductServices.DeleteProduct(productId, token)
        .then((result: boolean) =>
          dispatch({ type: 'RECEIVE_PRODUCT_DELETE', productId: productId })
        )
        .catch((error: ErrorType) =>
          dispatch({
            type: 'FAIL_ON_PRODUCT_DELETE',
            error: error
          })
        );
    }
};

export interface ProductState {
  isLoading: boolean;
  loadingSuccess: boolean | undefined;
  failOnLoading: boolean;
  isSaving: boolean;
  isFiltered: boolean;
  filterCriteria: string | undefined;
  productos: Producto[];
  producto?: Producto;
  lastDensity: number | undefined;
  StatusOk: boolean;
  Error?: ErrorType;

  //delete
  isDeleting: boolean;
  isDeleteSuccess: boolean | undefined;
  failOnDelete: boolean;

  //cost
  isGettingCost: boolean;
  GettingCostSuccess: boolean | undefined;
  failOnGettingCost: boolean;
  Cost: ProductCost | undefined;

  //ReplacementCost
  isGettingReplacementCost: boolean;
  GettingReplacementCostSuccess: boolean | undefined;
  failOnGettingReplacementCost:  boolean;
  ReplacementCost: IReplacementCost | undefined;

  //AverageCost
  isGettingAverageCost: boolean;
  GettingAverageCostSuccess: boolean | undefined;
  failOnGettingAverageCost:  boolean;
  AverageCost: IAverageCost | undefined;
}

const unloadedState: ProductState = {
  isLoading: false,
  loadingSuccess: undefined,
  failOnLoading: false,
  isSaving: false,
  isFiltered: false,
  filterCriteria: undefined,
  StatusOk: true,
  productos: [],
  lastDensity: undefined,

  isGettingCost: false,
  GettingCostSuccess: undefined,
  failOnGettingCost: false,
  Cost: undefined,

  isDeleting: false,
  isDeleteSuccess: undefined,
  failOnDelete: false,

  isGettingReplacementCost: false,
  GettingReplacementCostSuccess: undefined,
  failOnGettingReplacementCost: false,
  ReplacementCost: undefined,

  isGettingAverageCost: false,
  GettingAverageCostSuccess: undefined,
  failOnGettingAverageCost: false,
  AverageCost: undefined
};

export const reducer: Reducer<ProductState> = (
  state: ProductState | undefined,
  incomingAction: Action
): ProductState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'UPDATE_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      };
    case 'REQUEST_ALL_PRODUCTS':
      return {
        ...state,
        isFiltered: action.isFiltered,
        loadingSuccess: undefined,
        failOnLoading: false,
        filterCriteria: action.filterCriteria,
        isLoading: false,
        StatusOk: true,
        Error: undefined
      };
    case 'RECEIVE_ALL_PRODUCTS':
      return {
        ...state,
        isFiltered: action.isFiltered,
        loadingSuccess: true,
        failOnLoading: false,
        filterCriteria: action.filterCriteria,
        isLoading: false,
        StatusOk: true,
        Error: undefined,
        productos: action.products
      };
    case 'GET_ALL_PRODUCTS_FAIL':
      return {
        ...state,
        loadingSuccess: false,
        failOnLoading: true,
        isLoading: false,
        StatusOk: true,
        Error: action.error
      };
    case 'SET_PRODUCT':
      return {
        ...state,
        producto: action.product,
        StatusOk: true,
        Error: undefined
      };
    case 'REQUEST_SAVE_PRODUCT':
      return {
        ...state,
        isSaving: true,
        StatusOk: true,
        Error: undefined
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        StatusOk: action.StatusOK,
        Error: action.Errors
      };
    case 'SET_PRODUCT_FORMULA':
      return {
        ...state,
        producto: state.producto
          ? { ...state.producto, formula: action.formula }
          : undefined
      };
    case 'REQUEST_PRODUCT_COST':
      return {
        ...state,
        isGettingCost: true,
        GettingCostSuccess: undefined,
        failOnGettingCost: false,
        Error: undefined,
        Cost: undefined
      };
    case 'RECEIVE_PRODUCT_COST':
      return {
        ...state,
        isGettingCost: false,
        GettingCostSuccess: true,
        failOnGettingCost: false,
        Error: undefined,
        Cost: action.productCost
      };
    case 'PRODUCT_COST_FAIL':
      return {
        ...state,
        isGettingCost: false,
        GettingCostSuccess: false,
        failOnGettingCost: true,
        Error: action.error,
        Cost: undefined
      };
      case 'REQUEST_REPLACEMENT_COST':
      return {
        ...state,
        isGettingReplacementCost: true,
        GettingReplacementCostSuccess: undefined,
        failOnGettingReplacementCost: false,
        Error: undefined,
        ReplacementCost: undefined
      };
    case 'RECEIVE_REPLACEMENT_COST':
      return {
        ...state,
        isGettingReplacementCost: false,
        GettingReplacementCostSuccess: true,
        failOnGettingReplacementCost: false,
        Error: undefined,
        ReplacementCost: action.productCost
      };
    case 'PRODUCT_REPLACEMENT_COST_FAIL':
      return {
        ...state,
        isGettingReplacementCost: false,
        GettingReplacementCostSuccess: false,
        failOnGettingReplacementCost: true,
        Error: action.error,
        ReplacementCost: undefined
      };
      case 'REQUEST_AVERAGE_COST':
      return {
        ...state,
        isGettingAverageCost: true,
        GettingAverageCostSuccess: undefined,
        failOnGettingAverageCost: false,
        Error: undefined,
        AverageCost: undefined
      };
    case 'RECEIVE_AVERAGE_COST':
      return {
        ...state,
        isGettingAverageCost: false,
        GettingAverageCostSuccess: true,
        failOnGettingAverageCost: false,
        Error: undefined,
        AverageCost: action.productCost
      };
    case 'PRODUCT_AVERAGE_COST_FAIL':
      return {
        ...state,
        isGettingAverageCost: false,
        GettingAverageCostSuccess: false,
        failOnGettingAverageCost: true,
        Error: action.error,
        AverageCost: undefined
      };
    case 'REQUEST_PRODUCT_DELETE':
      return {
        ...state,
        isDeleting: true,
        isDeleteSuccess: undefined,
        failOnDelete: false,
        Error: undefined
      };
    case 'RECEIVE_PRODUCT_DELETE':
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: true,
        failOnDelete: false,
        Error: undefined,
        productos: state.productos.filter(
          (product: Producto) => product.id !== action.productId
        )
      };
    case 'FAIL_ON_PRODUCT_DELETE':
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        failOnDelete: true,
        Error: action.error
      };
      case 'REQUEST_LAST_DENSITY':
      return {
        ...state,
        lastDensity: undefined
      };
    case 'GET_LAST_DENSITY':
      return {
        ...state,
        lastDensity: action.density
      };
    case 'RECEIVE_SAVED_PRODUCT':
      if (state.productos) {
        return {
          ...state,
          productos: [
            ...state.productos.filter(
              (producto: Producto) => producto.id !== action.producto.id
            ),
            action.producto
          ].sort(Functions.DynamicSort('codigo')),
          producto: action.producto,
          isSaving: false,
          StatusOk: true,
          Error: undefined
        };
      };
  }

  return state;
};
