import * as React from 'react';
import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { ListaFormula } from '../../model/lista_formula';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import * as FormulaStore from '../../stores/Formula';
import * as ProductStore from '../../stores/Productos';
import { ItemPasos } from './itemPasos';
import { MateriaPrima } from '../../model/materia_prima';
import { ListaPasosDrag } from './listaPasosDrag';
import { Pasos } from '../../model/pasos';

//styles
import '../../custom.css';
import './formula.css';
import producto from '../../pages/producto';
export interface IProps {
  FormulaId: number;
  ProductAmount?: number;
  byAverage: boolean;
  OnErrorEvent?: (Error: string[]) => void;
}

export const PasosFormProducto = (props: IProps) => {
  const dispatch = useDispatch();
  const [orden, setOrden] = React.useState(0);
  const [totalSuma, settotalSuma] = useState<number>(0);
  const [PercentageControl, setPercentageControl] = useState<number>(0);
  const [showForm, setshowForm] = useState<boolean>(false);
  const [componentsError, setcomponentsError] = useState<string[]>([]);

  //state
  const FormulaState = useSelector((state: ApplicationState) => state.formulas);
  const ProductosState = useSelector(
    (state: ApplicationState) => state.productos?.producto
  );
  const ProductDensityState = useSelector(
    (state: ApplicationState) => state.productos?.lastDensity
  );
  const SuppliesState = useSelector(
    (state: ApplicationState) => state.Supplies
  );

  useEffect(() => {
    if (ProductosState && ProductosState.formula) {
      dispatch(
        mapDispatchToProps.setPasosFormula(
          ProductosState.formula.lista_elementos
        )
      );
      dispatch(mapDispatchToProps.GetLastDensity(ProductosState.id));
      setOrden(ProductosState.formula.lista_elementos.length);
      reset(ProductosState.formula);
    }
  }, [ProductosState]);

  useEffect(() => {
    let valorSuma: number = 0;
    if (FormulaState?.pasos) {
      FormulaState?.pasos.map((paso: Pasos) => {
        console.log('Paso_Valor', Number(paso.valor!));
        if (!paso.pausa) valorSuma += Number(paso.valor!);
        console.log('Paso_ValorSuma', Number(valorSuma.toFixed(3)));
        return true;
      });
    }
    settotalSuma(Number(valorSuma.toFixed(3)));
    setPercentageControl(Number(valorSuma.toFixed(3)));
  }, [FormulaState?.pasos]);

  useEffect(() => {
    let CurrentErros: string[] = [];
    if (totalSuma > 0) {
      if (props.byAverage) {
        if (totalSuma < 100)
          CurrentErros = [
            ...CurrentErros,
            'La suma de los componentes debe sumar 100%, porcentaje actual: ' +
              totalSuma +
              '%'
          ];
        if (totalSuma > 100)
          CurrentErros = [
            ...CurrentErros,
            'La suma de los componentes debe sumar 100%, porcentaje actual: ' +
              totalSuma +
              '%'
          ];
      } else {
        if (props.ProductAmount) {
          if (totalSuma < props.ProductAmount)
            CurrentErros = [
              ...CurrentErros,
              'La suma de los componentes actualmente es: ' +
                totalSuma +
                'Kg y tiene que sumar: ' +
                props.ProductAmount +
                'Kg'
             ];
          if (totalSuma > props.ProductAmount)
            CurrentErros = [
              ...CurrentErros,
              'La suma de los componentes actualmente es ' +
                totalSuma +
                'Kg y tiene que sumar ' +
                props.ProductAmount +
                'Kg'
            ];
        }
      }
    }
    setcomponentsError(CurrentErros);
  }, [totalSuma]);

  useEffect(() => {
    if (props.OnErrorEvent) props.OnErrorEvent(componentsError);
  }, [componentsError]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<ListaFormula>({});
  //const onSubmit = handleSubmit(data => console.log(data));
  const onSubmit = (data: any) => {};

  const addPaso = (paso: Pasos) => {
    SuppliesState?.RawMaterials?.map((materia: MateriaPrima) => {
      if (paso.materiaprima && paso.materiaprima.id === materia.id) {
        paso.materiaprima = materia;
      }
    });
    //paso.orden = orden + 1;
    //setOrden(paso.orden);
    dispatch(mapDispatchToProps.addPasosFormula(paso));
    setshowForm(false);
  };
  const eliminarPaso = (value: Pasos) => {
    console.log(value);
    dispatch(mapDispatchToProps.deletePasosFormula(value, props.FormulaId));
  };
  const editPaso = (value: Pasos) => {
    console.log(value);
    value.isEdicion = true;
    dispatch({ type: 'SET_PASO', paso: value });
    setPercentageControl(PercentageControl - (value.valor || 0));
    setshowForm(true);
  };
  const cancelPaso = () => {
    dispatch({
      type: 'SET_PASO',
      paso: {
        id: -1,
        formulaid: -1,
        valor: 0,
        pausa: false,
        minutospausa: 0,
        observacion: ''
      }
    });
    setPercentageControl(totalSuma);
    setshowForm(false);
  };

  const updateLista = (value: Pasos[]) => {
    let i = 0;
    let pasosOrden: { id: number; orden: number }[] = [];
    value.map((v: Pasos) => {
      v.orden = i;
      i++;
      pasosOrden.push({ id: v.id, orden: v.orden });
    });
    dispatch({ type: 'SET_PASOS', pasos: value });
    dispatch(mapDispatchToProps.updateOrden(props.FormulaId, pasosOrden));
  };

  const AddFormulaComponentClickHandler = () => {
    setshowForm(true);
  };

  const buttons = [
    {
      btnText: 'Eliminar',
      btnFunc: eliminarPaso,
      btnclass: 'btn btn-outline-danger'
    },
    {
      btnText: 'Editar',
      btnFunc: editPaso,
      btnclass: 'btn btn-outline-secondary'
    }
  ];
  return (
    <>
      <div className="contentpage">
        <h4>
          Fórmula{' '}
          <button
            className="btn btn-primary btn-sm"
            onClick={AddFormulaComponentClickHandler}
          >
            Agregar componente{' '}
          </button>
        </h4>
        {showForm && (
          <>
            <div className="PasosForm">
              <ItemPasos
                FormulaId={props.FormulaId}
                AddEvent={addPaso}
                CancelEvent={cancelPaso}
                isProducto={true}
                RemainginPercentage={Number((Number(100) - Number(PercentageControl)).toFixed(3))}
              ></ItemPasos>
            </div>
          </>
        )}
        <label>
          Total componentes:{' '}
          <strong>
            {totalSuma} {props.byAverage ? '%' : 'Kg.'}
          </strong>
        </label>
        <label> | Costo de Reposición: <strong>{
          // suma de materia prima, precio reposicion
          FormulaState?.pasos?.filter((mp: Pasos) => !mp.pausa ).reduce((acc, mp: Pasos) => acc + (mp.materiaprima?.precioReposicion ?? 0) * ((mp?.valor || 1) / 100) , 0).toFixed(3)
          }</strong> X (densidad: {ProductDensityState || 'última densidad no informada'}) = {FormulaState?.pasos?.filter((mp: Pasos) => !mp.pausa ).reduce((acc, mp: Pasos) => acc + (mp.materiaprima?.precioReposicion ?? 0) * ((mp?.valor || 1) / 100) , 0).toFixed(3) || 1 * (ProductDensityState || 1)}</label>
        <ListaPasosDrag
          data={FormulaState?.pasos}
          update={updateLista}
          buttons={buttons}
          isProducto={true}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.formulas
});

const mapDispatchToProps = {
  ...FormulaStore.actionCreators,
  ...ProductStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasosFormProducto as any);
